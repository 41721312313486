"use client";

import { apiDataset } from "@/lib/api-method";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Image from "next/image";
import Link from "next/link";

export default function FocusBar() {
  const datasets = [
    {
      id: "6667c34399988edf23f867af",
      name: "CNS 24hr",
      icon: "newspaper",
      column: "value",
      decimal: 4,
    },
    {
      id: "6667c7e0a5117daa8fe4e760",
      name: "CNS 4hr",
      icon: "newspaper",
      column: "value",
      decimal: 4,
    },
    {
      id: "667b88d14dfc28392262681e",
      name: "BTCUSDT",
      icon: "currency-bitcoin",
      column: "close",
      decimal: 2,
    },
    {
      id: "667b8cf6e9742646e7861195",
      name: "ETHUSDT",
      icon: "currency-euro",
      column: "close",
      decimal: 2,
    }
  ]
  
  const [data, setData] = useState<any>({});

  useEffect(() => {
    function fetchData() {
      datasets.map((dataset) => {
        apiDataset(dataset.id, 2)
          .then(([status, data]) => {
            if (status == 200) {
              setData((prev: any) => {
                return {
                  ...prev,
                  [dataset.id]: data,
                }
              });
            }
          });
      });

      setTimeout(fetchData, 60000);
    }

    fetchData();
  }, []);

  return (
    <div className="bg-dark text-white">
      <Container className="py-1 pt-3">
        <div className="row justify-content-md-center">
          {
            datasets.map((dataset) => {
              return (
                <div className="col-auto" key={dataset.id}>
                  <h5>
                    <i className={`bi bi-${dataset.icon}`}></i>
                    &nbsp;
                    <Link href={`/datasets/${dataset.id}`}>
                      { dataset.name }
                    </Link>
                    &nbsp;=&nbsp;
                    { data[dataset.id] != null ? data[dataset.id]["data"][data[dataset.id]["data"].length - 1][dataset.column].toFixed(dataset.decimal) : "..." }
                    {
                      data[dataset.id] != null && data[dataset.id]["data"][1][dataset.column] > data[dataset.id]["data"][0][dataset.column] ? (
                        <i className="bi bi-caret-up-fill text-success"></i>
                      ) : (
                        <i className="bi bi-caret-down-fill text-danger"></i>
                      )
                    }
                  </h5>
                </div>
              )
            })
          }
        </div>
      </Container>
    </div>
  )
}